import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST, THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';

import useInvitation from '@hooks/useInvitation';

// const TEXT_SHADOW = 'rgba(12,12,12,.8)';

function LeftContent() {
  const isInvitation = useInvitation();

  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={`linear-gradient(rgb(50 48 48/ 40%), rgb(50 48 48 / 40%)),url(${BG_DESKTOP_VIEW})`}
      bgSize="cover"
      bgPosition={'center'}
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
    >
      <Box height="100%" padding="42px">
        <WithAnimation>
          <Text
            color="white"
            textColor="secondaryColorText"
            fontFamily="headingAlternative"
            fontWeight="normal"
            fontSize="md"
            letterSpacing="2px"
          >
            {isInvitation ? 'HIPPOCRATIC OATH BATCH I 2024' : 'HIPPOCRATIC OATH BATCH I 2024'}
          </Text>
        </WithAnimation>
        <Box
          paddingTop={'32px'}
          textColor="secondaryColorText"
          textTransform="uppercase"
          letterSpacing="4px"
        >
          {false && (
            <Heading
              size="4xl"
              fontFamily={'heading'}
              fontWeight="light"
              fontSize="7xl"
              textTransform="uppercase"
            >
              {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &
            </Heading>
          )}
          <Heading
            marginTop="-16px"
            size="7xl"
            fontFamily={'heading'}
            fontWeight="light"
            fontSize="5xl"
            textTransform="uppercase"
          >
            {THE_BRIDE}
          </Heading>
        </Box>
        <WithAnimation>
          <Text
            color="white"
            fontWeight="light"
            fontStyle="italic"
            maxWidth="800px"
            letterSpacing="1px"
          >
            Have the courage to start, the perseverance to continue <br />
            and the kindness to make it all beautiful. <br />
            <b>- Maxime Lagacé -</b>
          </Text>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default LeftContent;
